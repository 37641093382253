<template lang="pug">
  .not-found
    .d-flex.flex-column.align-items-center
      h1 404
      a(@click="goBack") Go Back
</template>

<script>
  import { SIMPLE_LAYOUT } from "@/config/constants"

  export default {
    layout: SIMPLE_LAYOUT,

    methods: {
      goBack() {
        this.$router.go(-1)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .not-found
    align-items: center
    display: flex
    height: 100vh
    justify-content: center

    h1
      color: $default-gray
      font-size: 5em
    a
      color: $default-gray
      cursor: pointer

      &:hover
        text-decoration: underline
</style>
